<template>
    <div>
        <div class="row">
          <div class="col-xl-12 d-flex justify-content-end gutter-b">
            <span class="d-flex align-items-center mr-5">
              {{ $t("goodwemonitoring.title.last_sync") }} : <span class="font-weight-bolder ml-2 text-success">{{ battery.updated_at | moment("D.M.YYYY HH:mm:ss") }}</span>
            </span>
            <span class="btn btn-success" @click.prevent="resyncDevice" ref="resync_btn">
              <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg src="/media/svg/icons/General/Update.svg" />
              </span>
              {{ $t("goodwemonitoring.button.sync") }} 
            </span>
            <router-link
                :to="{ name: 'goodwemonitoring_plant_detail', params: { id: battery.plant_id }}"
                class="btn btn-icon btn-primary btn-xxl ml-2"
                v-b-tooltip.bottom='$t("goodwemonitoring.meta.plant_detail")'
                >
                <span class="svg-icon svg-icon-md ">
                  <inline-svg src="/media/svg/icons/Code/Git4.svg" />
                </span>
              </router-link>
              <router-link
                :to="{ name: 'goodwemonitoring_batteries'}"
                class="btn btn-outline-primary btn-xxl ml-2"
                >
                {{ $t("button.back") }}
              </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-6">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-primary svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Devices/Battery-half.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h3 text-dark mb-1">{{ battery.model_type }}</span>
                    <span class="text-muted font-weight-bold">{{ battery.battery_id }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                  <div class="text">
                    <span v-if="battery.is_stored == 1"><small class="fas fa-check text-success pr-1"></small> {{ $t("goodwemonitoring.batteries.label.hybird") }}</span>
                  </div>
                </div>
                <!--end::Section-->
                <!--begin::Content-->
                <div class="d-flex flex-wrap mt-8">
                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.status") }}</span>
                    <span v-if="battery.status == 1" class="btn btn-success btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.generating") }}</span>
                    <span v-if="battery.status == -1" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.offline") }}</span>
                    <span v-if="battery.status == 0" class="btn btn-warning btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.waiting") }}</span>
                    <span v-if="battery.status == 2" class="btn btn-danger btn-sm font-weight-bold btn-upper btn-text">{{ $t("goodwemonitoring.label.fault") }}</span>               
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.arm_version") }}</span>
                    <span class="btn btn-primary btn-sm font-weight-bold btn-upper btn-text">{{ battery.arm_version }}</span>            
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.firmware") }}</span>
                    <span class="btn btn-primary btn-sm font-weight-bold btn-upper btn-text">{{ battery.firmware_version }}</span>            
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.temperature") }}</span>
                    <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ battery.inner_tempperature }}°</span>            
                  </div>
                  
                </div>

                <div class="d-flex flex-wrap mt-5">
                  <div class="mr-12 d-flex flex-column mb-7">
                      <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.capacity") }}</span>
                      <span class="btn btn-secondary btn-sm font-weight-bold btn-upper btn-text">{{ battery.capacity }} kW</span>
                  </div>

                  <div class="mr-12 d-flex flex-column mb-7">
                      <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.check_code") }}</span>
                      <span class="btn  btn-sm font-weight-bold btn-upper btn-text">{{ battery.check_code }}</span>
                  </div>

                  <div class="d-flex flex-column mb-7 align-items-center w-25">
                    <span class="d-block font-weight-bold mb-4">{{ $t("goodwemonitoring.batteries.label.soc") }}</span>
                    <div class="progress progress-xs mx-3 mt-2 w-100">
                      <div class="progress-bar bg-success" role="progressbar" :style="{ width: battery.soc + '%'}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span v-if="battery.soc" class="font-weight-bolder text-dark mt-2">{{ battery.soc }}%</span>
                    <span v-else class="text-muted">-</span>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Card-->
          </div>

          <div class="col-xl-6">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
                <!--begin::Body-->
                <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-primary svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Electric/Highvoltage.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.batteries.title.technic_informations") }}</span>
                 
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->
                  <table class="table mt-4">
                    <tr>
                      <td>pv_input_1_v</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.pv_input_1_v ">{{ battery.pv_input_1_v }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>

                      <td>meter_phase1</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.meter_phase1 ">{{ battery.meter_phase1 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>

                    </tr>
                    <tr>
                      <td>pv_input_1_a</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.pv_input_1_a ">{{ battery.pv_input_1_a }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                      <td>meter_phase2</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.meter_phase2">{{ battery.meter_phase2 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>pv_input_2_v</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.pv_input_2_v ">{{ battery.pv_input_2_v }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                      <td>meter_phase3</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.meter_phase3">{{ battery.meter_phase3 }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>pv_input_2_a</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.pv_input_2_a ">{{ battery.pv_input_2_a }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                      <td></td>
                      <td class="font-weight-bolder">
                          <span></span>
                      </td>
                    </tr>
                  </table>  

                  <div class="d-flex align-items-center mt-2 px-3">
                    <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                      <span class="mr-4">
                        <i class="flaticon-interface-8 display-4 text-muted font-weight-bold"></i>
                      </span>
                      <div class="d-flex flex-column text-dark-75">
                        <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.batteries.label.output_voltage") }}</span>
                        <span class="font-weight-bolder font-size-h5">
                        <span class="text-dark-50 font-weight-bold"></span>{{ battery.output_voltage }}</span>
                      </div>
                    </div>

                    <div class="d-flex align-items-center flex-lg-fill mt-5 mb-2">
                      <span class="mr-4">
                        <i class="flaticon-interface-8 display-4 text-muted font-weight-bold"></i>
                      </span>
                      <div class="d-flex flex-column text-dark-75">
                        <span class="font-weight-bold font-size-sm">{{ $t("goodwemonitoring.batteries.label.output_power") }}</span>
                        <span class="font-weight-bolder font-size-h5">
                        <span class="text-dark-50 font-weight-bold"></span>{{ battery.output_power }}</span>
                      </div>
                    </div>
                  </div>

                </div>
                <!--end::Body-->

            </div>
          </div>
				</div>

        <div class="row">
          <div class="col-xl-6">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
              <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-primary svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Code/Info-circle.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.batteries.title.other_informations") }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->

                <table class="table mt-4">
                  <tr>
                    <td width="40%">charge_current_limit_bms</td>
                    <td class="font-weight-bolder" width="30%">
                        <span v-if="battery.charge_current_limit_bms ">{{ battery.charge_current_limit_bms }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td width="20%">iac1</td>
                    <td class="font-weight-bolder" width="30%">
                        <span v-if="battery.iac1 ">{{ battery.iac1 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td>discharge_current_limit_bms</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.discharge_current_limit_bms ">{{ battery.discharge_current_limit_bms }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td>iac2</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.iac2 ">{{ battery.iac2 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td>total_generation</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.total_generation ">{{ battery.total_generation }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td>iac3</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.iac3 ">{{ battery.iac3 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td>daily_generation</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.daily_generation ">{{ battery.daily_generation }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td>vac1</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.vac1 ">{{ battery.vac1 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td>meter_active_power</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.meter_active_power ">{{ battery.meter_active_power }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td>vac2</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.vac2 ">{{ battery.vac2 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td>meter_reactive_power</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.meter_reactive_power ">{{ battery.meter_reactive_power }}</span>
                        <span v-else class="text-muted">-</span>
                    </td>
                    <td>vac3</td>
                    <td class="font-weight-bolder">
                        <span v-if="battery.vac3 ">{{ battery.vac3 }}</span>
                      <span v-else class="text-muted">-</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                  <!--begin::Section-->
                  <div class="d-flex align-items-center">
                    <!--begin::Pic-->
                    <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                      <span class="svg-icon svg-icon-primary svg-icon-xxl">
                      <inline-svg class="svg-icon" src="/media/svg/icons/Code/Info-circle.svg" />
                      </span>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <div class="d-flex flex-column mr-auto">
                      <!--begin: Title-->
                      <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.batteries.title.other_informations") }}</span>
                      <!--end::Title-->
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Section-->
                  <table class="table mt-4">
                    <tr>
                      <td>back_up_power</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.back_up_power ">{{ battery.back_up_power }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>back_up_voltage</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.back_up_voltage ">{{ battery.back_up_voltage }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>battery_v</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.battery_v ">{{ battery.battery_v }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>battery_a</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.battery_v ">{{ battery.battery_a }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>battery_w</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.battery_w ">{{ battery.battery_w }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                    <tr>
                      <td>warning_bms</td>
                      <td class="font-weight-bolder">
                          <span v-if="battery.warning_bms ">{{ battery.warning_bms }}</span>
                          <span v-else class="text-muted">-</span>
                      </td>
                    </tr>
                  </table>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-success svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Code/Commit.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.batteries.title.log_history") }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->

                  <perfect-scrollbar
                      class="navi navi-hover scroll mt-4"
                      style="max-height: 500px; position: relative;"
                      v-if="battery_log.length > 0"
                    >
                      <table class="table table-head-custom table-head-bg table-vertical-center" >
                        <thead>
                          <tr class="text-left text-uppercase">
                            <th class="text-center">iac1</th>
                            <th class="text-center">iac2</th>
                            <th class="text-center">iac3</th>
                            <th class="text-center">vac1</th>
                            <th class="text-center">vac2</th>
                            <th class="text-center">vac3</th>
                            <th class="text-right">{{ $t("goodwemonitoring.batteries.label.last_update") }}</th>
                          </tr>
                        </thead>
                  
                        <tbody v-if="!loading">
                          <tr v-for="(log, index) in battery_log" :key="index">
                              <td align="left">
                                <span v-if="log.iac1">{{log.iac1}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="center">
                                <span v-if="log.iac2">{{log.iac2}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="center">
                                <span v-if="log.iac3">{{log.iac3}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="center">
                                <span v-if="log.vac1">{{log.vac1}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="center">
                                <span v-if="log.vac2">{{log.vac2}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="center">
                                <span v-if="log.vac3">{{log.vac3}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="right">
                                {{log.updated_at | moment("D.M.YYYY HH:mm:ss")}}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </perfect-scrollbar>
                    <p v-else>{{ $t("goodwemonitoring.batteries.title.no_history_found") }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b card-stretch">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Section-->
                <div class="d-flex align-items-center">
                  <!--begin::Pic-->
                  <div class="flex-shrink-0 mr-4 symbol symbol-50 symbol-circle">
                    <span class="svg-icon svg-icon-danger svg-icon-xxl">
                     <inline-svg class="svg-icon" src="/media/svg/icons/Code/Time-schedule.svg" />
                    </span>
                  </div>
                  <!--end::Pic-->
                  <!--begin::Info-->
                  <div class="d-flex flex-column mr-auto">
                    <!--begin: Title-->
                    <span class="card-title font-weight-bolder font-size-h5 text-dark mb-1">{{ $t("goodwemonitoring.batteries.title.error_history") }}</span>
                    <!--end::Title-->
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Section-->

                <perfect-scrollbar
                      class="navi navi-hover scroll mt-4"
                      style="max-height: 500px; position: relative;"
                      v-if="battery_conditions_log.length > 0"
                    >
                      <table class="table table-head-custom table-head-bg table-vertical-center" >
                        <thead>
                          <tr class="text-left text-uppercase">
                            <th class="text-left">{{ $t("goodwemonitoring.batteries.label.condition_name") }}</th>
                            <th class="text-right">{{ $t("goodwemonitoring.batteries.label.last_update") }}</th>
                          </tr>
                        </thead>
                  
                        <tbody v-if="!loading">
                          <tr v-for="(filterlog, index) in battery_conditions_log" :key="index">
                              <td align="left">
                                <span v-if="filterlog.custom_filter">{{filterlog.custom_filter.name}}</span>
                                <span v-else>-</span>
                              </td>
                              <td align="right">
                                {{filterlog.updated_at | moment("D.M.YYYY HH:mm:ss")}}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </perfect-scrollbar>
                    <p v-else>{{ $t("goodwemonitoring.batteries.title.no_history_found") }}</p>

              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import { checkErrors } from "@/core/utils/api-errors";

export default {
  
  components: {

  },
  data() {
    return {
      battery: null,
      battery_log: null,
      battery_conditions_log: null,
    };
  },
  metaInfo () { 
      return { title: this.$t("goodwemonitoring.meta.battery_detail")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("goodwemonitoring.meta.devices"), subtitle: this.$t("goodwemonitoring.meta.battery_detail") }]);
    this.getBatteryDetail();
    this.getBatteryLog();
    this.getBatteryConditionLog();
  },
  methods: {
    getBatteryDetail() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/batteries/" + this.$route.params.id)
        .then((response) => {
          this.battery = response.data.data;
          this.loading = false;
        });
    },
    getBatteryLog() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/batteries/" + this.$route.params.id + "/data-logs?count=20")
        .then((response) => {
          this.battery_log = response.data.data;
          this.loading = false;
        });
    },
    getBatteryConditionLog() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet("/goodwe-monitoring/batteries/" + this.$route.params.id + "/filter-logs?count=20")
        .then((response) => {
          this.battery_conditions_log = response.data.data;
          this.loading = false;
        });
    },
    resyncDevice() {
        // Loading spinner
      const submitButton = this.$refs["resync_btn"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.loading = true;

      ApiService.apiGet("/goodwe-monitoring/plants/" + this.battery.plant_id + "/resync")
        .then(() => {
            this.loading = false;
            submitButton.classList.remove("spinner","spinner-light","spinner-right");
            this.getBatteryDetail();
        });
    }
  }
};
</script>
